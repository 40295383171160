import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ClassNameFormatter, cn } from '@bem-react/classname';
import closeIcon from './img/close-icon.png';
import './PrivacyPolicy.sass';

export interface PrivacyPolicyProps {
  dangerousText: string
  adoption?: boolean
  settingsRout?: string
  maxWidth?: number
  btnNameAccept?: string
  btnNameDecline?: string
}

interface PrivacyPolicyState {
  portalId: string
  isHidden: boolean
  loaded: boolean
  closed: string | null
  cnPrivacyPolicy: ClassNameFormatter
  el: HTMLDivElement
}

export function PrivacyPolicy({
  dangerousText,
  adoption,
  settingsRout,
  maxWidth,
  btnNameAccept,
  btnNameDecline,
}: PrivacyPolicyProps) {
  const [state, setState] = useState<PrivacyPolicyState>({
    portalId: 'pp-portal',
    isHidden: false,
    loaded: false,
    closed: sessionStorage.getItem('pp'),
    cnPrivacyPolicy: cn('PrivacyPolicy'),
    el: document.createElement('div'),
  });

  useEffect(() => {
    state.el.id = state.portalId;
    state.el.style.cssText = `
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 100;
      display: flex;
      justify-content: center;
      `;
    document.body.prepend(state.el);

    setState((st) => ({ ...st, loaded: true }));

    return () => {
      document.body.removeChild(state.el);
    };
  }, []);

  const closeHandler = () => {
    setState((st) => ({ ...st, isHidden: true }));
    setTimeout(() => {
      setState((st) => ({ ...st, loaded: false }));
      sessionStorage.setItem('pp', '1');
    }, 300);
  };

  const renderElement = () => {
    const staticLayout: boolean = maxWidth ? maxWidth <= 732 : false;

    return (
      <div className={state.cnPrivacyPolicy()}>
        <div
          className={state.cnPrivacyPolicy('Wrapper', { hidden: state.isHidden, adoption, staticLayout })}
          style={maxWidth ? { maxWidth } : undefined}
        >
          <p
            className={state.cnPrivacyPolicy('P', { adoption })}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: dangerousText }}
          />
          {adoption ? (
            <div className={state.cnPrivacyPolicy('ButtonWrapper', { staticLayout })}>
              <button
                type="button"
                className={state.cnPrivacyPolicy('ButtonAd', { staticLayout })}
                onClick={closeHandler}
              >
                {btnNameDecline || 'Не принимаю'}
              </button>
              <button
                type="button"
                className={state.cnPrivacyPolicy('ButtonAd', { accept: true, staticLayout })}
                onClick={closeHandler}
              >
                {btnNameAccept || 'Принимаю'}
              </button>
              {settingsRout ? (
                <button
                  type="button"
                  className={state.cnPrivacyPolicy('ButtonAd', { staticLayout })}
                  onClick={() => { window.location.assign(settingsRout); }}
                >
                  Настройки
                </button>
              ) : null}
            </div>
          ) : (
            <button
              type="button"
              className={state.cnPrivacyPolicy('Button')}
              onClick={closeHandler}
            >
              <img className={state.cnPrivacyPolicy('CloseIcon')} src={closeIcon} alt="" />
            </button>
          )}
        </div>
      </div>
    );
  };

  const portalElement = document.getElementById(state.portalId);

  return state.closed !== '1' && state.loaded && portalElement ? createPortal(
    renderElement(),
    portalElement,
  ) : null;
}
